<template>
	<section ref="qrPanel" class="qr-section">
		<client-only>
			<div class="qr-panel">
				<div class="row">
					<form class="booking bookForm" @submit.prevent="initBookingEngine">
						<div class="columns column4">
							<date-picker
								v-model:value="state.arrival"
								:clearable="false"
								:disabled-date="disableArrivalDates"
								value-type="YYYY-MM-DD"
								format="DD-MM-YYYY"
								:placeholder="bookingForm.checkin"
								class=""
								:lang="datepickerLang"
								@pick="pickArrival"
							/>
						</div>
						<div class="border" />
						<div class="columns column4">
							<date-picker
								v-model:value="state.departure"
								:open="state.openDeparture"
								:disabled-date="disableDepartureDates"
								value-type="YYYY-MM-DD"
								format="DD-MM-YYYY"
								:placeholder="bookingForm.checkout"
								:default-value="state.pickedArrivalDate ?? new Date()"
								class=""
								:lang="datepickerLang"
								@open="state.openDeparture = true"
								@close="state.openDeparture = false"
							/>
						</div>
						<div class="columns column4">
							<book-button
								class="button book-now cta qr"
								:arrival="state.arrival"
								:departure="state.departure"
							>
								{{ bookingForm.availability }}
							</book-button>
						</div>
					</form>
				</div>
			</div>
		</client-only>
	</section>
</template>

<script setup>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import datePickerTranslations from '~/config/datePicker.js';

const { locale } = useI18n();

defineProps({
	bookingForm: { type: Object, default: () => ({}) },
});

const state = reactive({
	arrival: null,
	departure: null,
	openDeparture: false,
	pickedArrivalDate: null,
});

const qrPanel = ref(null);

const datepickerLang = computed(() => datePickerTranslations[locale] ?? datePickerTranslations.en);

const pickArrival = (date) => {
	state.departure = '';
	state.pickedArrivalDate = date;
	state.openDeparture = true;
};

const disableArrivalDates = (date) => {
	const today = new Date();

	return date < today.setDate(today.getDate() - 1);
};

const disableDepartureDates = (date) => {
	if (!state.pickedArrivalDate) {
		return disableArrivalDates(date);
	}

	return date <= state.pickedArrivalDate;
};

onMounted(() => {
	qrPanel.value.classList.add('mounted');
});
</script>

<style>
.mx-datepicker {
	width: 100%;
}

.booking .mx-input {
	margin: 0 !important;
	width: 100% !important;
	padding: 15px 40px 15px 15px !important;
	height: 62px !important;
	text-transform: uppercase;
	border: none !important;
	font-weight: 600;
	color: #000;
}

.mx-datepicker svg {
	color: var(--header-color);
}
</style>

<style lang="scss" scoped>
.qr-section {
	text-align: center;
	margin: 0 auto;
	position: absolute;
	bottom: 80px;
	left: 0;
	right: 0;
	padding: 0;
	float: left;
	width: 100%;
	max-width: 680px;
	z-index: 10;
}

.qr-panel {
	float: none;
	padding: 0;
	width: 100%;
	margin: 0 auto;
	display: inline-block;
	position: relative;
	z-index: 2;
	text-align: center;
	background: #fff;

	::placeholder {
		color: #000;
	}

	.row {
		width: 100%;
		padding: 0;
	}

	.columns {
		padding: 0 7px 0 0;
		width: 29%;

		&:last-child {
			width: calc(42% + 1px);
			padding: 0;
		}
	}

	.border {
		width: 1px;
		background: var(--header-color);
		height: 34px;
	}

	form {
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
	}
}

.qr-panel input,
.qr-panel select {
	margin: 0 !important;
	width: 100% !important;
	padding: 15px 40px 15px 15px !important;
	height: 62px !important;
	border: none;
	text-transform: uppercase;
}

.qr-panel .button {
	padding: 0 10px !important;
	text-transform: uppercase;
	border: none !important;
	vertical-align: top;
	width: 100%;
	line-height: 62px;
	height: 62px;
}
</style>
